import React from 'react';
import {withRouter, Route} from 'react-router-dom';
import Switch from "react-bootstrap/Switch";
import Home from "pages/Home/Home";


const Routes = props => {
    return (
        <Switch>
            <Route path={"/"}>
                <Home/>
            </Route>
        </Switch>
    );
};

export default withRouter(Routes);