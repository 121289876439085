import React from 'react';
import {ReactTypical} from "@deadcoder0904/react-typical";

const Footer = props => {
    return (
        <div className="Footer">
            <ReactTypical
                steps={['Develompent in progress!']}
                wrapper="p"
            />
        </div>
    );
};

export default Footer;