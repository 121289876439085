import React from 'react';
import {Container} from "react-bootstrap";
import {Toolbar} from "components/navigation/Toolbar";
import Footer from "components/Footer";
import BgPattern from "assets/svg/bg_pattern.svg";

const Layout = props => {
    return (
        <div className="Layout">
            <div className="Layout__bg-pattern">
                <img src={BgPattern} className={'bg-pattern'} alt="bg-pattern"/>
                <div className="area">
                    <ul className="circles">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div>
            </div>
            <Toolbar/>
            <Container fluid className={'Layout__content-wrapper'}>
                {props.children}
            </Container>
            <Footer/>
        </div>
    );
};

export default Layout;