import React from 'react';

import About from "pages/Home/components/About";

const Home = props => {
    return (
        <div className="Home">
            <About/>
        </div>
    );
};

export default Home;