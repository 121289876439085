import React from "react";
import './App.scss';
import Layout from "layout/Layout";
import Routes from "layout/Routes";

function App() {
  return (
    <div className="App">
        <Layout>
            <Routes/>
        </Layout>

    </div>
  );
}

export default App;
