import * as React from "react";
import { motion } from "framer-motion";
import { MenuItem } from "./MenuItem";
import EmotePc from "assets/emotes/emotePc.png";
import EmoteHello from "assets/emotes/emoteHello.png";
import EmoteGreatJob from "assets/emotes/emoteGreatJob.png";



const variants = {
    open: {
        transition: { staggerChildren: 0.07, delayChildren: 0.2 }
    },
    closed: {
        transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
};

export const Navigation = () => (
    <motion.ul variants={variants}>
        {itemIds.map(item => (
            <MenuItem i={item.id} key={item.id} emote={item.emote} label={item.label} />
        ))}
    </motion.ul>
);

const itemIds = [
    {id:0, emote: EmotePc, label: 'Home',},
    {id:1, emote: EmoteHello, label: 'About',},
    {id:2, emote: EmoteGreatJob, label: 'Something',},
    {id:3, emote: EmoteGreatJob, label: 'Something 2',},
];
