import React from 'react';
import EmoteIcon from "components/icons/EmoteIcon";
import EmoteHello from "assets/emotes/emoteHello.png";
import EmotePC from "assets/emotes/emotePc.png";
import AnimationContainer from "components/AnimationContainer";
import {ReactTypical} from "@deadcoder0904/react-typical";

const About = props => {
    return (
        <div className="About">
            <img src={EmotePC} className={'About__pc-emote'} alt=""/>
            <h1 className={'About__title'}>
                Tomas Kuruc
                <ReactTypical
                    steps={['I\'m a Front-End Developer!']}
                    wrapper="p"
                />
            </h1>
            <a href={"mailto:tomkokuruc@gmail.com"} className={'btn-hireme'}>Hire me!</a>

            {/*<AnimationContainer>*/}
            {/*    <EmoteIcon icon={EmoteHello} height={230}/>*/}
            {/*</AnimationContainer>*/}
        </div>
    );
};

export default About;